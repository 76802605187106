body {
  font-family: "Roboto";
  overflow-x: hidden;
}

.card-style-layout {
  min-height: 50vh;
  /* padding-bottom: 20px; */
  border-radius: 10px;
}

.ant-modal-footer {
  display: none;
}

.hr-tag-line {
  position: absolute;
  bottom: 40px;
  width: 95%;
}

/* .table-pagination-class {
    min-height: 50vh;
} */
.table-pagination-class {
  min-height: 48vh;
}

.color-text-iq {
  color: var(--iq-primary);
}

/* Manual Add Fund start */

.add-fund-card {
  width: 100%;
  height: auto;
  background-color: white;
  /* box-shadow: 0 7px 15px 0 rgba(0, 0, 0, .13), 0 1px 4px 0 rgba(0, 0, 0, .11); */
  border-radius: 20px;
  padding: 15px 15px;
  border: 1px solid #e5e5e5;
}

.add-fund-card h2,
h3 {
  color: #294c28eb;
}

/* select box  */
.brd {
  border: 1px solid #e2eded;
  border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
}

#select-box {
  width: 100%;
}

#select-button {
  position: relative;
  height: 40px;
  width: 85%;
  padding: 12px 14px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

#options-view-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

#selected-value {
  font-size: 16px;
  line-height: 1;
  margin-right: 26px;
}

.option i {
  width: 16px;
  height: 16px;
}

.option,
.label {
  color: #2d3667;
  font-size: 16px;
}

#chevrons {
  position: absolute;
  top: -4px;
  right: 2px;
  bottom: 0;
  width: 21px;
  padding: 9px 20px;
}

#chevrons i {
  display: block;
  height: 50%;
  color: #d1dede;
  font-size: 20px;
  text-align: right;
}

#options-view-button:checked+#select-button #chevrons i {
  color: #2d3667;
}

.options {
  position: absolute;
  left: 0;
  width: 250px;
}

#options {
  position: absolute;
  top: 42px;

  right: 34px;
  left: 0;
  width: 81.5%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 4px;
}

#options-view-button:checked~#options {
  border: 1px solid #e2eded;
  border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
  background-color: white;
  z-index: 999;
}

.option {
  position: relative;
  line-height: 1;
  transition: 0.3s ease all;
  z-index: 2;
}

.option i {
  position: absolute;
  left: 14px;
  padding: 0;
  display: none;
}

#options-view-button:checked~#options .option i {
  display: block;
  padding: 12px 0;
}

.label {
  display: none;
  padding: 0;
  margin-left: 27px;
}

#options-view-button:checked~#options .label {
  display: block;
  padding: 12px 14px;
}

.s-c {
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
}

.s-c.top {
  top: 0;
}

.s-c.bottom {
  bottom: 0;
}

input[type="radio"] {
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  height: 50%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

.s-c:hover~i {
  color: #fff;
  opacity: 0;
}

.s-c:hover {
  height: 100%;
  z-index: 1;
}

.s-c.bottom:hover+i {
  bottom: -25px;
  animation: moveup 0.3s ease 0.1s forwards;
}

.s-c.top:hover~i {
  top: -25px;
  animation: movedown 0.3s ease 0.1s forwards;
}

@keyframes moveup {
  0% {
    bottom: -25px;
    opacity: 0;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes movedown {
  0% {
    top: -25px;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

.label {
  transition: 0.3s ease all;
}

.opt-val {
  position: absolute;
  left: 14px;
  width: 217px;
  height: 21px;
  opacity: 0;
  background-color: #fff;
  transform: scale(0);
}

.option input[type="radio"]:checked~.opt-val {
  opacity: 1;
  transform: scale(1);
}

.option input[type="radio"]:checked~i {
  top: 0;
  bottom: auto;
  opacity: 1;
  animation: unset;
}

.option input[type="radio"]:checked~i,
.option input[type="radio"]:checked~.label {
  color: #fff;
}

.option input[type="radio"]:checked~.label:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

#options-view-button:not(:checked)~#options .option input[type="radio"]:checked~.opt-val {
  top: -30px;
}

.option:nth-child(1) input[type="radio"]:checked~.label:before {
  background-color: #000;
  border-radius: 4px 4px 0 0;
}

.option:nth-child(1) input[type="radio"]:checked~.opt-val {
  top: -31px;
}

.option:nth-child(2) input[type="radio"]:checked~.label:before {
  background-color: #ea4c89;
}

.option:nth-child(2) input[type="radio"]:checked~.opt-val {
  top: -71px;
}

.option:nth-child(3) input[type="radio"]:checked~.label:before {
  background-color: #0057ff;
}

.option:nth-child(3) input[type="radio"]:checked~.opt-val {
  top: -111px;
}

.option:nth-child(4) input[type="radio"]:checked~.label:before {
  background-color: #32c766;
}

.option:nth-child(4) input[type="radio"]:checked~.opt-val {
  top: -151px;
}

.option:nth-child(5) input[type="radio"]:checked~.label:before {
  background-color: #f48024;
}

.option:nth-child(5) input[type="radio"]:checked~.opt-val {
  top: -191px;
}

.option:nth-child(6) input[type="radio"]:checked~.label:before {
  background-color: #006400;
  border-radius: 0 0 4px 4px;
}

.option:nth-child(6) input[type="radio"]:checked~.opt-val {
  top: -231px;
}

.option .fa-codepen {
  color: #000;
}

.option .fa-dribbble {
  color: #ea4c89;
}

.option .fa-behance {
  color: #0057ff;
}

.option .fa-hackerrank {
  color: #32c766;
}

.option .fa-stack-overflow {
  color: #f48024;
}

.option .fa-free-code-camp {
  color: #006400;
}

#option-bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 40px;
  transition: 0.3s ease all;
  z-index: 1;
  display: none;
}

#options-view-button:checked~#options #option-bg {
  display: block;
}

.option:hover .label {
  color: #fff;
}

.option:nth-child(1):hover~#option-bg {
  top: 0;
  background-color: #000;
  border-radius: 4px 4px 0 0;
}

.option:nth-child(2):hover~#option-bg {
  top: 40px;
  background-color: #ea4c89;
}

.option:nth-child(3):hover~#option-bg {
  top: 80px;
  background-color: #0057ff;
}

.option:nth-child(4):hover~#option-bg {
  top: 120px;
  background-color: #32c766;
}

.option:nth-child(5):hover~#option-bg {
  top: 160px;
  background-color: #f48024;
}

.option:nth-child(6):hover~#option-bg {
  top: 200px;
  background-color: #006400;
  border-radius: 0 0 4px 4px;
}

.dolor-ico {
  font-weight: 600;
  font-size: 30px;
  color: #294c28;
}

.dolor-nun {
  font-weight: 600;
  font-size: 30px;
  color: #294c28;
}

.ticket-card {
  background-color: #f2f2f2;
  border-radius: 10px;
  text-align: center;
  border: 1px solid #e5e5e5;
}

.ppt-tp {
  padding: 0 20px;
}

.name-cirle {
  width: 50px;
  height: 50px;
  background-color: #880303;
  border-radius: 100%;
}

.name-cirle p {
  text-align: center;
  padding: 7px 0;
  color: white;
  font-size: 21px;
  font-weight: 500;
  font-family: "poppins";
}

.dol-ico {
  font-size: 38px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0;
  color: #ffb500;
}

.cirle-name-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.amout-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cur-cls {
  border-left: 5px solid #ffb500;
  margin: 10px 0px;
}

.txrt-form {
  width: 25%;
  height: 45px;
  background-color: transparent;
  border: none;
  font-size: 41px;
  font-weight: 600;
  outline: none;
  display: block;
}

.txrt-form:focus {
  border: none;
  outline: none;
}

/* botton 1  */

.check-btn-dolor {
  width: 100%;
  padding: 5px 0px;
  background-color: #880303;
  color: white;
  border: none;
  border-radius: 4px;

  font-size: 16px;
  font-weight: 500;
}

.check-btn-dolor p {
  font-size: 18px;
  font-weight: 500;
}

.add-fund-btn-do {
  width: 100%;
  padding: 5px 0px;
  background-color: #f14336;
  color: white;
  border: none;
  border-radius: 4px;

  font-size: 16px;
  font-weight: 500;
}

.add-fund-btn-do p {
  font-size: 18px;
  font-weight: 500;
}

.myt-margin {
  margin: 55px 0 0 0;
}

.amout-label-name {
  font-weight: 600;
  font-size: 19px;
}

/* botton 1  */
.card-head-rebbon {
  background: white;
  box-shadow: 4px 4px 15px rgba(#000, 0.15);
  position: relative;
  color: #434343;
  font-size: 18px;
  font-weight: 700;
}

.card-head-rebbon .card__container {
  padding: 2rem;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 1rem;
  position: relative;
}

.card-head-rebbon .card__header {
  margin-bottom: 1rem;
  font-family: "Playfair Display", serif;
}

.card-head-rebbon.card__body {
  font-family: "Roboto", sans-serif;
}

.card-head-rebbon::before {
  position: absolute;
  top: 23px;
  left: -21px;
  content: "";
  background: #880303;
  height: 28px;
  width: 27px;
  transform: rotate(45deg);
  z-index: -1;
}

.card-head-rebbon::after {
  position: absolute;
  content: attr(data-label);
  left: -27px;
  padding: 2px 30px;
  background: #880303;
  color: white;
  text-align: center;
  font-family: "Roboto", sans-serif;
  /* box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2); */
  box-shadow: -1px 0px 2px rgba(26, 35, 126, 0.2);
  border-radius: 5px 5px 5px 0px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Manual Add Fund end */

/* manual own css */
.header-card-style-layout {
  height: 11vh;
}

.header-text-chart {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: White;
  /* margin-left: 100px; */
  border-radius: 7px;
  padding: 10px 10px;
}

.bundle-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3px 14px 3px 8px;
  border-radius: 5px;
  /* border-left: 3px solid #626262; */
  /* background-color: #58585c12; */
  /* background-color: #eaeaeba8; */

  /* box-shadow: -4px 5px 0px rgb(0 148 179 / 12%); */
  /* box-shadow: -4px 5px 0px rgb(63 65 77 / 13%); */
}

.Account {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3px 14px 3px 8px;
  border-radius: 5px;
  /* border-left: 3px solid #626262; */
  /* background-color: #58585c12; */

  /* box-shadow: -4px 5px 0px rgb(0 148 179 / 12%); */
  /* box-shadow: -4px 5px 0px rgb(63 65 77 / 13%); */
}

.total-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3px 34px 3px 8px;
  border-radius: 5px;
  /* border-left: 3px solid #626262; */
  /* background-color: #58585c12; */

  /* box-shadow: -4px 5px 0px rgb(0 148 179 / 12%); */
  /* box-shadow: -4px 5px 0px rgb(63 65 77 / 13%); */
}

.total {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bundl-text {
  font-size: 18px;
  font-weight: 500;
  color: #7a7c7f;
}

.bqyt-text {
  color: #63ad64;

  font-weight: 700;
  font-size: 18px;
}

.accblane-text {
  font-size: 18px;
  font-weight: 500;
  color: #7a7c7f;
  text-align: center;
}

.zero-text {
  color: #af2323;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}

.totl-text {
  font-size: 18px;
  font-weight: 500;
  color: #7a7c7f;
}

.sixthy-text {
  color: #63ad64;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}

.parent-filter {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  padding: 10px 20px;
  border-radius: 7px;
  /* border: 3px solid #7620ac; */
  /* background-color: #fff; */
  margin-bottom: 0px;
}

.filter-resign {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  margin: 0px 0 12px 0;
}

.carding {
  /* overflow-y: scroll; */
  height: 397px;
  /* height: 417px; */
}

.cat {
  margin: 10px 0px 10px 0px;
  background-color: #f9f9fa8f;
  border-radius: 4px;
  overflow: hidden;
  /* float: left; */
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  transition: all 0.2s ease-in;
  /* padding: 8px 0px; */
}

.cat:hover {
  background-color: #294c28eb;
}

.cat input+span:hover {
  color: #fff !important;
}

.accordion-body {
  padding: 0px 15px;
}

.cat label {
  float: left;
  /* line-height: 3.0em; */
  width: 8em;
  height: 3em;
  display: contents;
}

.cat label input+span {
  color: #222020 !important;
}

.cat label span {
  text-align: center;
  display: inline-block;
  width: 100%;
  /* height: 35px; */
  /* text-align: start; */
  padding: 6px;
}

.cat label input {
  position: absolute;
  display: none;
  color: #222020 !important;
}

/* selects all of the text within the input element and changes the color of the text */
.cat label input+span {
  color: #222020 !important;
}

span.dropdown-toggle.text-primary {
  font-size: 12px;
}

/* This will declare how a selected input will look giving generic properties */
.cat input:checked+span {
  color: #ffffff;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.452);
}

.action input:checked+span {
  background-color: #294c28eb;
  color: #fff !important;
}

.position-set {
  position: relative;
}

.position-set-1 {
  position: absolute;
  top: 7px;
}

#accordionFlushExample1 {
  height: 100%;
  overflow: auto !important;
}

.confirm-order-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.confirm-order-card-style-layout {
  /* height: 80vh; */
  width: 70%;
  border-radius: 10px;
  padding: 10px;
  /* overflow: scroll; */
}

.confirm-order-card-inner-style-layout {
  /* height: 50vh; */
  /* overflow-y: scroll; */
  /* padding: 0px 10px; */
}

.confirm-order-card-inner-style-layout {
  /* height: 50vh; */
  /* overflow-y: scroll; */

  background-color: #fff;
  /* box-shadow: 0 0 10px rgb(229 229 229); */
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 3px inset;
  padding: 3px 3px;
  border-radius: 10px;
}

.confirm-order-header {
  display: flex;
  justify-content: space-between;
  color: black;
  font-weight: 500;
}

.confirm-order-quantity-box {
  /* border: 2px solid rgb(224 224 224); */
  padding: 10px 20px;
  color: black;
  border-radius: 10px;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.confirm-order-footer {
  display: flex;
  justify-content: end;
  color: black;
  font-weight: 600;
  margin-top: 15px;
}

.footer-value {
  color: green;
  padding-left: 20px;
  font-weight: 900;
}

/* .confirm-order-quantity-button {
    display: flex;
    justify-content: end;
} */

.cart-amount-summary {
  width: 300px;
  height: 50%;
  background-color: #efefef;
}

.card-summary-style-layout {
  height: 40vh;
  padding: 10px;
}

.selected-amount-summary {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.amount-header {
  font-size: 17px;
}

.amount-value {
  font-size: 17px;
  font-weight: 500;
  color: black;
}

.summary-button {
  display: flex;
  justify-content: center;
}

.select-bar-manul-add-fund {
  padding: 0 0px;
}

.form-control {
  height: 38px;
  line-height: 20px;
  background: transparent;
  border: 1px solid #ededed;
  font-size: 14px;
  color: #515151;
  border-radius: 5px;

  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

/* .btn-manul-add-fund-row {
    padding: 0 40px;
} */

.add-fund-card h2,
h3 {
  font-family: "Roboto";
  font-weight: 500;
  color: #294c28eb;
  font-size: 22px;
  margin-left: 5px;
}

.invoice-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

.table-head tr {
  background-color: #1da0bc;
  /* color: white; */
}

.table-body tr:nth-of-type(even)>* {
  background-color: #f6f5f1;
}

/* --------Spinner----------------- */

.spinnerInner {
  width: 8px;
  height: 8px;
  animation: spinner-z355kx 1.2s infinite linear;
  border-radius: 8px;
  box-shadow: 20px 0px 0 0 #294c28eb, 12.4px 15.6px 0 0 #294c28eb,
    -4.4px 19.4px 0 0 #294c28eb, -18px 8.6px 0 0 #294c28eb,
    -18px -8.6px 0 0 #294c28eb, -4.4px -19.4px 0 0 #294c28eb,
    12.4px -15.6px 0 0 #294c28eb;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 60%;
}

@keyframes spinner-z355kx {
  to {
    transform: rotate(360deg);
  }
}

.multi-bulk-btn {
  background: #3498db;
  border-color: #3498db;
}

.acti-btn {
  background: #e58904;
  border-color: #e58904;
}

.acti-export-btn-v {
  background: #27b345;
  border-color: #27b345;
}

/* --------Spinner----------------- */

.tariff-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.variation-mode {
  /* width: 180px; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.variation-price-change [disabled] {
  font-weight: 500 !important;
  border-radius: 6px 0px 0px 6px !important;
  background-color: #a9a8a8 !important;
}

.variation-price-change input {
  font-weight: 500;
  border-radius: 0px 6px 6px 0px !important;
  font-size: 14px !important;
  border: 1px solid #a9a8a8 !important;
}

.variation-mode input {
  width: 74px;
  border-radius: 2px;
}

.bundle-header {
  /* border: 2px solid #dddddd; */
  /* text-align: center; */
  /* box-shadow: 0px 0px 2px #f5f5f5; */
  /* background-color: #0088a8; */
  /* color: #fff; */
  border-radius: 2px;
  padding: 0px 5px;
  padding-bottom: 5px;
  color: #3d3d3d;
  font-size: 14px;
  font-weight: 400;
}

/* .variation-price-change {
    display: flex;
    justify-content: center;
    align-items: center;
} */

/* .modelPlanPage {
    padding: 1rem 2.5rem 1rem 1.5rem !important;
} */

.model-plan-purchase {
  padding: 0px;
}

.plan-purchase-content-container {
  padding: 7px 26px 0px 12px;
  /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
}

.plan-purchase-image-container {
  /* width: 350px; */
  position: relative;
  padding: 0;
}

.plan-purchase-image-container img {
  width: 100%;
  /* height: auto; */
  height: 450px;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  color: white;
}

.confirm-order-plan-desc {
  /* display: flex; */
}

.confirm-order-plan-desc span {
  display: flex;
  align-items: center;
  /* margin-left: 10px; */
}

.confirm-order-plan-p {
  margin-left: 10px;
  font-size: 20px;
  margin-top: -17px;
  color: #294c28eb;
  cursor: pointer;
  padding-top: 20px;
}

.owl-carousel .owl-nav .owl-next {
  right: -7%;
  top: -80px;
}

.owl-carousel .owl-nav .owl-prev {
  left: -7%;
  top: -80px;
}

.iq-card {
  border-radius: 15px;
  /* margin-bottom: 3px; */
  /* margin-top: 20px; */
  border: none;
  box-shadow: none;
  border: 1px solid #e5e5e5;
}

.iq-card .iq-card-header {
  min-height: 30px;
  margin-top: 1em;
}

/* .owl-carousel .owl-stage-outer {
    margin-top: 15px;

} */

.owl-theme .owl-nav [class*="owl-"] {
  font-size: 50px !important;
}

/* .iq-card-block.iq-card-height {
    height: calc(100% - 30px);

    padding-bottom: 24px;
} */

.increment-button {
  background: #294c28eb;
  border: transparent;
  width: 30px;
  height: 30px;
  color: #fff;
}

/* new-style-css-17  */
.fab-icon-code {
  font-size: 19px;
  cursor: pointer;
  background-color: #00bcd4 !important;
  padding: 4px 8px !important;
  border-radius: 100% !important;
  color: white !important;

  padding-bottom: 24px;
}

/* .iq-card-header-toolbar {
 border: 1px dotted #0088a8; 
  border-radius: 5px;
  padding: 0px 10px;
} */

.table-inner-style {
  overflow: scroll;
  height: 50vh;
  margin-top: 10px;
}

.ui-amount-due {
  /* padding: 5px 10px; */
  /* display: flex;
    justify-content: end; */
  border: 1px solid #dbd5d5;
  border-radius: 5px;
}

.plan-h {
  font-weight: 500;
  margin-bottom: 0px;
}

.plan-v {
  color: var(--iq-primary);
  font-size: 12px;
}

.plan-price {
  font-size: 18px;
  color: var(--iq-dark);
  font-weight: 700;
}

.iq-bg-primary-hover:hover {
  background: #fbfbfb !important;
}

.media-body h6 {
  font-size: 14px;
  font-weight: 600;
}

.rounded-top {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.view-card-bplan {
  background-color: #294c28eb;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  transition: all 0.5s ease-out 0s;
}

.view-card-bplan:hover {
  color: #fff !important;
}

.add-bundle-cart {
  background-color: #00bcd4;
  padding: 5px 8px 5px 0px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
}

.remove-btn-cart {
  border-radius: 5px;
}

.bg-span-cart {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 3px inset;
  padding: 8px 8px;
  border-radius: 5px;
}

.bg-cart-b {
  background-color: #00bcd447;
  padding: 4px 42px 5px 6px;
  border-radius: 5px;
}

.inp-cart {
  border: none;
  border-radius: 4px;
}

.input-container {
  position: relative;
}

.input-cart-cart {
  font-size: 1em;
  padding: 0.6em 1em;
  border: none;
  border-radius: 6px;
  background-color: #f8f8f8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: 100% !important;
  color: #333;
}

.input-cart-cart:hover {
  background-color: #f2f2f2;
}

.input-cart-cart:focus {
  outline: none;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.input-cart-cart::placeholder {
  color: #999;
}

.highlight {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #294c28eb;
  transition: width 0.3s ease;
}

.input-cart-cart:focus+.highlight {
  width: 100% !important;
}

.purchase-cart-b {
  border-radius: 5px;
  font-weight: 700;
}

.planPuchaseTable {
  height: 40vh !important;
  min-height: 40vh !important;
}

.confirm-order-plan-desc {
  padding-top: 6px;
}

.data-list-add {
  padding-top: 20px;
}

.decrmnet-mainus {
  background: #747272;
  color: #fff;
  border: none;
  border-radius: 4px 0px 0px 4px;
  width: 50px;
  height: 30px;
  transition: all 0.1s ease-in-out;
}

.incrmnt-plus {
  background: #747272;
  color: #fff;
  border: none;
  border-radius: 0px 4px 4px 0px;
  width: 50px;
  height: 30px;
  transition: all 0.1s ease-in-out;
}

.incrmnt-plus:nth-last-child(1):active {
  /* box-shadow: inset -4px 5px 10px rgba(0, 0, 0, 0.5); */
  background: #00bcd4;
}

.decrmnet-mainus:nth-child(1):active {
  /* box-shadow: inset 4px 5px 10px rgba(0, 0, 0, 0.5); */
  background: #00bcd4;
}

.buttonbgsec {
  /* background-color: #58585817; */
  /* padding: 5px; */
  border-radius: 5px;
}

.amount-para-due {
  font-weight: 600;
  font-size: 15px;
  color: #212529;
}

.model-cart-details {
  background: rgb(255 255 255 / 18%);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.8px);
  -webkit-backdrop-filter: blur(17.7px);
  padding: 8px;
  width: 14rem;
  transform: translate(57px, 10px);
}

.model-footer-qty {
  background: rgb(233 233 233 / 58%);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(17.7px);
  -webkit-backdrop-filter: blur(17.7px);
  border: 5px solid rgba(255, 255, 255, 0.99);
  padding: 2px 8px;
  width: 216px;
  position: absolute;
  bottom: 9px;
}

.qty-input-cart {
  width: 50px;
  margin: 0px 10px;
  padding: 0 0px 0 16px;
  border-radius: 3px;
  border: 3px solid #fff;
}

.base-country-input-cart {
  width: 100%;
  border-radius: 5px !important;
  padding: 10px 14px;
  border: 4px solid rgb(255 255 255) !important;
  margin-top: 10px;
  /* box-shadow: 1px -1px 6px rgba(0, 0, 0, 0.03), 3px -3px 24px rgba(0, 0, 0, 0.05) !important; */
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0px 0px 0px rgba(0, 0, 0, 0.11),
    0 4px 4px rgba(0, 0, 0, 0.11), 0 6px 8px rgba(0, 0, 0, 0.11),
    1px 0px 0px 0px rgba(0, 0, 0, 0.11);
  background-color: #f2f2f2;
}

/* purshase page  */
.purchese-plan-input-r {
  width: 100%;
  border-radius: 5px !important;
  padding: 10px 14px !important;
  border: 4px solid rgb(255 255 255) !important;
  margin-top: 10px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0px 0px 0px rgba(0, 0, 0, 0.11),
    0 4px 4px rgba(0, 0, 0, 0.11), 0 6px 8px rgba(0, 0, 0, 0.11),
    1px 0px 0px 0px rgba(0, 0, 0, 0.11);
  background-color: #eeeeee;
}

.model-footer-qty-purchase {
  background: rgb(233 233 233 / 58%);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(17.7px);
  -webkit-backdrop-filter: blur(17.7px);
  border: 5px solid rgba(255, 255, 255, 0.99);
  padding: 2px 8px;
  width: auto;
}

.model-cart-details-purshase {
  background: rgb(255 255 255 / 18%);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.8px);
  -webkit-backdrop-filter: blur(17.7px);
  padding: 18px;
  width: 17rem;
  transform: translate(26px, 120px);
}

.form-popup-purchase {
  position: absolute;
  bottom: 0;
  left: 3px;
  right: 0;
  margin: 0;
  padding: 0;
  background-color: #fff;
  box-shadow: 1px -1px 6px rgba(0, 0, 0, 0.03),
    3px -3px 24px rgba(0, 0, 0, 0.05) !important;
  width: 96%;
}

.footer-purchase-pup {
  width: 328px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* border: 4px solid rgb(255 255 255) !important;
    background-color: #e7e7e7;
    box-shadow: 1px -1px 6px rgba(0, 0, 0, 0.03), 3px -3px 24px rgba(0, 0, 0, 0.05) !important; */
}

.button-purshase-btn {
  width: 180px;
}

.div-set-purchase-pup {
  padding: 0 26px 0 10px;
}

.row-purchase-footer {
  padding: 7px 0px 5px 13px;
}

.botton-height-purshase {
  height: 35px !important;
}

/* purchase  */

.PhoneInputInput {
  border: none;
  background: transparent;
}

/* css-19-1-24  */
.remove-btnagg {
  background: rgb(241, 67, 54);
  border-radius: 5px;
  color: white;
  padding: 5px 10px 5px 10px;
  border: none;
  margin-top: 13px;
  cursor: pointer;
  margin-right: 5px;
}

.remove-btnagg-1 {
  background: #294c28eb;
  border-radius: 5px;
  color: white;
  padding: 7px 14px 7px 14px;
  border: none;
}

.remove-div-p {
  padding: 38px 0 0 56px;
}

/* .input-width {
    width: 250px;
} */

/* cart  */
.confirm-overflow-inner {
  max-height: 245px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.table-body-overflow {
  max-height: 245px;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* .table-over-flow {
    max-height: 245px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 30px;
} */

/* cart  */
li.shouldActive .active {
  color: var(--iq-primary) !important;
}

/* table tbody {
    max-height: 40vh;
    display: block;
    overflow-y: scroll;
} */

/* table thead,
table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
} */

/* .number-dots {
    height: 5px;
    width: 5px;
    font-size: 15px;
    
    text-align: center;
    padding: 0;
    position: absolute;
    top: -10px;
    right: 10px;
  
    border-radius: 50%;
    -webkit-border-radius: 50%;
} */

.number-dots {
  height: 16px;
  width: 16px;
  font-size: 10px;
  text-align: center;
  position: absolute;
  top: 14px;
  right: 4px;
  animation: shadow-pulse-dots 1s infinite;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  background-color: #f14336;
  padding: -2px 0px 0 2px;
  color: white;
}

.table-over-flow {
  overflow: auto;
  height: 275px;
}

/* css-code-22-1-24 */
/* .variation-mode {
    background-color: #e1e1e1;
    padding: 12px 0;
    width: 85%;
    border-radius: 5px 5px 5px 5px;
    margin: 0 auto;
    background-image: url(/public/assets/images/tariff-img.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

} */

#input-desable {
  font-weight: 500 !important;
  border-radius: 6px 0px 0px 6px;
  background-color: #a9a8a8 !important;
  color: #191919;
  font-size: 15px !important;
}

#input-enable {
  font-weight: 500;
  border-radius: 0px 6px 6px 0px;
  color: #191919;
  font-size: 14px;
  border: 1px solid #a9a8a8;
}

.variation-mode input {
  width: 74px;
  border-radius: 0px 0px 0px 0px;
  border: none;
  padding: 3px 5px;
  color: #191919;
  font-size: 15px;
}

.tariff-groupbtn {
  background-color: #0088a8;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  border: none;
  padding: 5px 32px;
}

.tariff-gruop-red-btn {
  background-color: #f14336;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  border: none;
  padding: 5px 31px;
}

/* css-code-22-1-24 */
table.fixed-table tbody {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}

/* 
#audit-report-li {
    background-color: #ddfbffb8;
    padding: 2px 22px;
    border-radius: 3px;
    color: #00bcd4;
    text-decoration: none;
} */

.btn-seach-btn {
  background-color: #0088a8;
  border: none;
  border-radius: 5px;
  color: white;
}

.excel-btn-1-aidit {
  border: none !important;
  border-radius: 3px !important;
}

.fixed-table thead,
.fixed-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table td,
.table th {
  padding: 0.4rem;
}

.t-thead .t-th,
.t-td {
  width: 50%;
}

label {
  display: inline-block;
  margin-bottom: 0.3rem;
  font-size: 14px;
  font-weight: 600;
}

.modal-header {
  padding: 0.5rem 1rem;
}

.modal-title {
  font-weight: 700;
}

/* .table thead th {
  background: #ebebeb;
} */

.table thead th {
  background: #fff5f5;
}

.btn {
  border-radius: 5px;
}

/* css-code-22-1-24 */

.tab-content-profile {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
  background-color: #faf8f8;
  border-radius: 5px 5px 0px 0px;
}

#profileuper-card {
  border-radius: 3px;
  background: linear-gradient(45deg, #1994b196, #fec33170);
  border-radius: 5px 5px 0px 0px;
  padding: 0px 0px 45px 0px;
}

#profile-profile {
  padding-bottom: 30px;
}

.top-headerprofile {
  background-color: #294c28eb;
  border-radius: 5px 5px 0px 0px;
  background-image: url(/public/assets/images/hospital_bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.card-title-about {
  text-align: center;
  padding-top: 2px;
  color: #fff;
}

.para-my-profil {
  font-size: 17px;
  color: white;
  font-weight: 500;
  text-align: center;
  padding-top: 7px;
}

.user-profil-my-pro {}

#lower-part-profile {
  position: relative;
  width: 85%;
  margin: -20px 0px 0 0px;
  border-radius: 8px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  padding: 15px;
}

.header-part-profile-p {
  position: absolute;
  top: -16px;
  padding: 3px 15px;
  background-color: #294c28eb;
  border-radius: 3px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* .card-title {
    color: white;
    text-align: center;
    padding-top: 5px;
    font-size: 17px;
    font-weight: 500;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8
} */

#user-cirle-my-pro {
  background: #0081a2ad;
  width: 70px;
  height: 70px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  -webkit-user-select: none;
  user-select: none;
}

#user-cirle-my-pro p {
  font-size: 40px;
  font-weight: 600;
}

.para-user-name-1 {
  color: #4a4a4a;
  font-weight: 500;
  font-size: 16px;
}

.iq-card .iq-card-header {
  min-height: 0px;
  margin-top: 1em;
}

.lowe-part-profile-inner {
  background-color: #fdf7e791;
  border-radius: 10px;
  padding-top: 25px;
}

.about-user-th {
  font-weight: 600;
  font-size: 16px;
}

.width-define {
  width: 82%;
  text-align: left;
}

.tab-content-profile {
  background-image: url(/public/assets/images/gre.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.profile-center-panel-1 {
  /* padding-top: 121px; */
  padding-bottom: 30px;
}

.line-height {
  line-height: 42px !important;
}

/* new css 24-1-24 */

/* new-payment-css-29  */
.card-style-success-p {
  padding: 20px !important;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px !important;
  background-color: #fff !important;
  border-radius: 10px !important;
  position: relative !important;
}

.payment-content-text h4 {
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;
  color: #37d26d;
}

.para-success-text {
  line-height: 24px;
  font-size: 17px;
}

.confirm-order-inner-succes {
  background-color: #e6ffe680 !important;
  border-radius: 10px !important;
  height: auto;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.payment-successfulbtn {
  border: none;
  border-radius: 5px;
  background-color: #37d26d;
  color: #fff;
  padding: 5px 30px;
}

/* new-payment-css-29  */
/* new-faild-css-30 */
.card-style-success-p-feaild {
  padding: 20px !important;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px !important;
  background-color: #fff !important;
  border-radius: 10px !important;
  position: relative !important;
}

.payment-content-text-feaild h4 {
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;
  color: #cb2027;
}

.para-success-text-feaild {
  line-height: 24px;
  font-size: 17px;
}

.confirm-order-inner-succes-feaild {
  background-color: #f6dbdb5c !important;
  border-radius: 10px !important;
  height: auto;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.payment-successfulbtn-feaild {
  border: none;
  border-radius: 5px;
  background-color: #cb2027;
  color: #fff;
  padding: 5px 30px;
}

.pagination-plan-purches {
  padding-bottom: 15px;
}

.email-table table ul,
p {
  white-space: break-spaces;
}

.card-title {
  font-weight: 700;
}

.iq-card .iq-card-header .iq-header-title .card-title {
  margin-bottom: 7px;
}

input:focus::placeholder {
  color: transparent;
}

.Activity_searchBtn__GlBtC {
  height: 38px;
  width: 100px;
  margin-top: 3px;
}

/* new-faild-css-30 */
.iq-action-icon-box {
  height: 27px;
  width: 27px;
  line-height: 25px;
  text-align: center;
  margin-right: 10px;
  font-size: 18px;
  text-decoration: none;
}

li.shouldActive .active {
  color: var(--iq-white) !important;
  background-color: var(--iq-primary);
}

/* new css mvno 14-24  */
.shouldActive:has(a.active) {
  color: transparent !important;
  background: transparent !important;
  border-radius: 0;
  border: none !important;
}

.border-primary {
  border-color: #2a4d29 !important;
}

.icon-shadow-mvno {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.switchClass {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* new css mvno 14-24  */

fieldset {
  border: 1px solid #7e7c7c;
  margin: 10px;
  padding: 5px 20px;
  width: 97%;
  border-radius: 10px;

}

ul.dat-tab-list {
  padding: 0;
  margin: 0;
}

ul.dat-tab-list li {
  padding: 5px 10px;
  display: inline-block;
}


legend {
  border-radius: 10px;
  padding: 3px 15px;
  width: auto;
  font-size: 17px;
  color: #000000;
  font-weight: 700;
  margin: 0;
}

.title {
  float: left;
  width: 120px;
  text-align: left;
  padding-right: 5px;
}

select#region {
  font-family: courier;
  cursor: grab;
}

input[type="submit"] {
  border: 1px solid #a87cba;
  background-color: #cca2e2;
  font-family: courier;
  border-radius: 5px;
}

.submit {
  text-align: right;
  padding-right: 10px;
}

input[type="submit"]:hover {
  border: 2px solid #9254b3;
}

.card-1 {
  /* z-index: 0; */
  /* border: none; */
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 5px;

  position: relative;
}


.css-t3ipsp-control:hover {
  border-color: #880303 !important;
  outline: none !important;
  box-shadow: 0 0 1px #880303;
}

.css-t3ipsp-control:active {
  border-color: #880303 !important;
  outline: none !important;
  box-shadow: 0 0 1px #880303;
}

.css-t3ipsp-control {
  border-color: #880303 !important;
  outline: none !important;
  box-shadow: 0 0 1px #880303 !important;
}

#activationRequestTable td:last-child {
  min-width: 300px;
  text-wrap: unset;
  max-width: 100px !important;
  width: auto;
}

.p-3-set {
  padding: 7px 15px 7px 15px;
  border-radius: 8px !important;
}

.toDayActivation {
  border-radius: 8px;
}

.badge-textStyle {
  width: 60px;
  height: 34px;
  line-height: 1.3;
  text-transform: capitalize;
  font-size: 15px;
  text-align: center;
  padding: 8px 0 0 0;
}

.recharge-form-tble Table th:last-child {
  width: 150px;

}

.wrapped-text {
  display: block;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.asset-seacrt-br {
  width: 76%;
}

.asset-seacrt-br .searchbox {
  width: 100%;
}

.asset-table-line .hr-tag-line {
  width: 94%;
  bottom: 5px !important;

}

.asset-activation-div {
  height: calc(100% - (0px + 20px));
}

/* new css  */
.txrt-form {
  width: 44%;

}

/* TABLE FIXED CSS */
.plan-price-mapping .table-pagination-class .table-pagination-class #user-list-table th:nth-child(1) {
  position: sticky;
  left: -1px;
}

.plan-price-mapping .table-pagination-class .table-pagination-class #user-list-table th:nth-child(2) {
  position: sticky;
  left: 27px;
}

.plan-price-mapping .table-pagination-class .table-pagination-class #user-list-table td:nth-child(1) {
  position: sticky;
  left: -1px;
}

.plan-price-mapping .table-pagination-class .table-pagination-class #user-list-table td:nth-child(2) {
  position: sticky;
  left: 27px;
}

.plan-price-mapping .table-pagination-class .table-pagination-class #user-list-table tr:nth-child(odd) td:nth-of-type(1),
.plan-price-mapping .table-pagination-class .table-pagination-class #user-list-table tr:nth-child(odd) td:nth-of-type(2) {
  background-color: #fff;
}

.plan-price-mapping .table-pagination-class .table-pagination-class #user-list-table tr:nth-child(even) td:nth-of-type(1),
.plan-price-mapping .table-pagination-class .table-pagination-class #user-list-table tr:nth-child(even) td:nth-of-type(2) {
  background-color: #f2f2f2;
}

.agg-network-list {
  margin-right: 7px;
  background-color: #5b585817 !important;
  padding: 5px 10px;
  border-radius: 4px;
}

.dashboard-activation-loader,
.dashboard-activation-loader-month,
.dashboard-activation-loader-last-month {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 216px;
}

.dashboard-activation-loader .spinner-border-sm {
  width: 3rem;
  height: 3rem;
  border-width: 0.3em;
  margin-top: 90px;
}

.dashboard-activation-loader-month .spinner-border-sm {
  width: 3rem;
  height: 3rem;
  border-width: 0.3em;
  margin-top: 50px;
}

.dashboard-activation-loader-last-month .spinner-border-sm {
  width: 3rem;
  height: 3rem;
  border-width: 0.3em;
  margin-top: 50px;
  margin-bottom: 50px;
}

.dashboard-activation-loader-activation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.request-info-sim {
  border: 2px solid #747474;
  padding: 10px 10px;
  border-radius: 5px
}

.sim-swap-heading {
  font-weight: 400;
  color: #3f414d;
}

.check-confrm-btn {
  font-weight: 650;
  background-color: #dea705;
  color: #fff !important;
}

.email-card-list {
  padding: 10px 10px 10px 10px;
  height: 40px;
  /* background: #f2f6fc; */
  background: #f2f6fc;
  border-bottom: 1px solid #e1e7ee;
  transition: all 0.2s ease;

}

.email-card-list:hover {

  box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 9px 19px -9px, rgb(0 0 0 / 15%) 0px -2px 0px inset;
  /* box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 7px 13px -3px, rgb(0 0 0 / 10%) 0px -3px 0px inset; */
  margin-bottom: 1px;
}

.card-email-detail-card {
  background-color: #fff;

  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 7px;

}

.img-no-dta {
  width: 30%;
}

.details-inner-card {
  padding: 15px;
}

.wrong-icon {
  display: flex;
  align-items: center;
  justify-content: end;
  border-bottom: 1px solid #46464614;
  padding: 10px 16px 0 0px;
  height: 55px;
}

.feb-arrow-icon {
  margin-bottom: 8px;
  width: 40px;
  height: 40px;
  /* background: #5151511c; */
  border-radius: 50px;
  text-align: end;
  display: flex;
  justify-content: center;
  background: #c2c2c261;

  padding: 1px 2px 0 0;
}

.feb-arrow-icon .icon-circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: transparent;
  color: #050505;
  font-size: 18px;
  height: 36px;
  width: 36px;
}

.read {
  font-weight: 700;
  color: black;
  background: #f2f6fc;
  background-color: #fff;
  /* font-size: ; */
}

.unread {
  background: #f2f6fc;
}

.search-email-box {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  padding: 11px 15px 11px 19px;
  margin-bottom: 25px;
}

.email-inbox-data {
  background-color: #ffffff;

  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  padding: 11px 11px 11px 11px;
  border-radius: 15px;

}

.email-search-msisdn {
  font-size: 15px;
  font-weight: 700;
  padding: 0px 0px 7px 8px;
}

/* .email-inbox-data .infinite-scroll-component {
  overflow-x: hidden !important;
  height: 300px !important;
  overflow-y: auto !important;
} */
.email-inbox-data .infinite-scroll-component {
  overflow: hidden !important;
}

.infinite-scroll-loader .spinner-border-sm {
  width: 2rem;
  height: 2rem;
  margin-top: 12px;
}



.activation_count_parent table tr th {
  color: #000000;

}

.activation_count_parent {
  margin-bottom: 1.5em;
}

.activation_count_parent table {
  border-collapse: separate;
  border-spacing: 0;
}

.activation_count_parent table tr th,
table tr td {
  border-right: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  padding: 2px 5px;
}

.activation_count_parent table tr th:first-child,
table tr td:first-child {
  border-left: 1px solid #bbb;
}

.activation_count_parent table tr th {
  background: #eee;
  border-top: solid 1px #bbb;
}

/* top-left border-radius */
.activation_count_parent table tr:first-child th:first-child {
  border-top-left-radius: 5px;
}

/* top-right border-radius */
.activation_count_parent table tr:first-child th:last-child {
  border-top-right-radius: 5px;
}

/* bottom-left border-radius */
.activation_count_parent table tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}

/* bottom-right border-radius */
.activation_count_parent table tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

.activation_count_parent table div {
  line-height: 2.5;
}

.planNetwork {
  height: 92px;
}

.networkSearchBtn {
  margin-top: 26px;
}

.data-row {
  display: flex;
  justify-content: space-between;
}

.example-label {
  font-style: italic;
  font-weight: bold;
}

.data-heading {
  font-weight: bold;
  width: 48%;
  color: red;
  font-size: 13px;
}

.data-row span {
  width: 48%
}

.data-dot {
  white-space: nowrap;
  font-size: 20px;
  color: #999;
  margin-top: -13px;
}

.portinBackButton{
margin-top: -35px !important;
}